<template>
  <div>
    <h2 :class="variantStyle()" class="text-balance">
      {{ title }}
    </h2>

    <h3 v-if="subtitle" class="my-4 text-center text-gray-900">
      {{ subtitle }}
    </h3>

    <hr
      v-if="variant == 'primary'"
      class="w-24 h-1 m-auto mt-4 leading-3 border-none bg-cred"
    />
  </div>
</template>

<script setup lang="ts">
import { Variant } from '@/types/enums'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  variant: {
    type: String as PropType<Variant>,
    default: Variant.primary,
  },
})

function variantStyle() {
  switch (props.variant) {
    case Variant.primary:
      return ['text-3xl', 'font-bold', 'text-center', 'sm:text-3']
    case Variant.secondary:
      return ['text-2xl', 'font-bold', 'text-cblack']
    case Variant.tertiary:
      return ['text-md', 'font-bold', 'text-cblack']
  }
}
</script>
